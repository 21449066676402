import React, { useState } from 'react';
import { sendContactForm } from '../services/contactService';
import ReactGA from 'react-ga4';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [responseMessage, setResponseMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setResponseMessage('Please wait...');

        setIsSubmitting(true);

        ReactGA.event({
            category: 'User',
            action: 'Clicked Contact Form [Send] Button',
            label: 'Contact Page'
        });

        try {
            await sendContactForm(formData);
            setResponseMessage('Message sent successfully!');
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            setResponseMessage('Failed to send message. Please try again.');
        }
        setIsSubmitting(false);
    };

    return (
        <div>
            <h1>Drop me a line</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Message:</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send'}
                </button>
            </form>
            {responseMessage && <p>{responseMessage}</p>}
        </div>
    );
};

export default Contact;