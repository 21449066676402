import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllBlogs } from '../services/blogService';

const Builds = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        async function fetchBlogs() {
            const data = await getAllBlogs();
            setBlogs(data);
        }
        fetchBlogs();
    }, []);

    return (
        <div>
            <h1>Builds List</h1>
            <ul>
                {blogs.map((blog) => (
                    <li key={blog.slug}>
                        <Link to={`/builds/${blog.slug}`}>{blog.title}</Link>
                    </li>
                ))}
            </ul>
            <Link to="https://kathy.works">(Old Blog)</Link>
            <p>Link to the old blog will remain active until I'm done migrating the posts over here.</p>
        </div>
    );
};

export default Builds;
