import React from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
    const location = useLocation();

    const menuItems = [
        { name: 'Home', href: '/' },
        { name: 'About', href: '/about' },
        { name: 'Blog', href: '/builds' },
        { name: 'Contact', href: '/contact' },
      ];

      return (
        <div className="mt-8 mb-6 text-txt-rose-gold font-press-start text-[16px] font-bold">
          <div className="space-y-4">
            {menuItems.map((item) => (
              <Link key={item.name} to={item.href} className="group flex items-center">
                <span
                  className={`mr-2 ${
                    location.pathname === item.href || (item.href !== '/' && location.pathname.startsWith(item.href))
                      ? 'visible'
                      : 'invisible'
                  } group-hover:visible`}
                >
                  {'>'}
                </span>
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      );
}

export default Header;
