import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Builds from './pages/Builds';
import BlogPost from './pages/BlogPost';
import Contact from './pages/Contact';
import Header from './components/Header';
import { initGA, trackPageView } from './utils/analytics';

function App() {

  useEffect(() => {
    initGA();
  }, []);

  return (
      <Router>

          <Header />

          <GAListener />

          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/builds" element={<Builds />} />
              <Route path="/builds/:slug" element={<BlogPost />} />
              <Route path="/contact" element={<Contact />} />
          </Routes>
      </Router>
  );
}

// Component to track page views
const GAListener = () => {

  const location = useLocation();

  useEffect(() => {
      trackPageView(location.pathname);
  }, [location]);

  return null;
};

export default App;

