import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { useParams, Link } from 'react-router-dom';
import { getBlogBySlug } from "../services/blogService";

const BlogPost = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        async function fetchBlog() {
            const data = await getBlogBySlug(slug);
            setBlog(data);
        }
        fetchBlog();
    }, [slug]);

    if (!blog) return <h1>Loading...</h1>;

    return (

        <div>
            <h1>{blog.title}</h1>
            <p>{blog.content}</p>
            {/* <div style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                <Link to={`/builds/${blog.latestPostNumber}`}>&#124;&lt; Latest</Link>
                &nbsp; | &nbsp;
                <Link to={`/builds/${blog.newerPostNumber}`}>&lt; Newer</Link>
                &nbsp; | &nbsp;
                <Link to={`/builds/random`}>&#91;Random&#93;</Link>
                &nbsp; | &nbsp;
                <Link to={`/builds/${blog.previousPostNumber}`}>&gt; Previous</Link>
                &nbsp; | &nbsp;
                <Link to={`/builds/1`}>&#124;&gt; First</Link>
            </div> */}
        </div>
    );
};

export default BlogPost;