import React from 'react';

const Home = () => {
    return (
        <div>
            <p>Currently building:</p>
            <h1>Web 3.14</h1>
        </div>
    );
};

export default Home;
