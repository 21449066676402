import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL + "/api/contact"

export const sendContactForm = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/send`, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending contact form:', error);
        throw new Error('Failed to send message.');
    }
};
