import React from 'react';

const About = () => {

    return (
        <div>
            <h1>About Kathy</h1>
            <p>Kathy builds.</p>
            <p>Among things she builds are analog, software, hardware, <a href="https://buildiful.com">Buildiful</a> builds, and more.</p>
            <p>"Build what you can. Build what (you thought) you couldn't."</p>
        </div>
    );
};

export default About;
