import ReactGA from 'react-ga4';

const GA_TRACKING_ID = process.env.REACT_APP_GA_ID;  // Use environment variable for GA ID

export const initGA = () => {
    if (GA_TRACKING_ID) {
        ReactGA.initialize(GA_TRACKING_ID);
    }
};

export const trackPageView = (path) => {
    if (GA_TRACKING_ID) {
        ReactGA.send({ hitType: "pageview", page: path });
    }
};
